
<template>
  <v-container id="filter-control" class="pa-0 mt-4" :class="{ collapsed: isCollapsed }" >
    <v-row>
      <v-col md="12">
        <v-select label="Select a Category" :items="categories" variant="outlined"
                  density="compact" item-title="text" bg-color="white" placeholder="Select a Category"
                  item-value="value" class="mb-n4" v-model="selectedCategory"
        ></v-select>
      </v-col>
      <v-col md="12" v-if="selectedCategory === 'owner_name'">
        <div class="input-dropdown" @click.stop>
          <v-text-field type="text" label="Owner Name" variant="outlined" density="compact"
                        v-model="ownerName" @input="fetchOwnerByName(ownerName)" class="mb-n5" bg-color="white" :loading="ownerLoader"
          />
          <!-- Dropdown to display search results -->
          <ul v-show="ownerSearchResults.length" class="dropdown mt-n4" role="listbox">
            <li v-for="result in ownerSearchResults" :key="result.id" @click="selectOwner(result)" role="option">
              {{ result.name }}
            </li>
          </ul>
        </div>
      </v-col>
      <v-col md="12" v-else>
        <v-select variant="outlined" density="compact" placeholder="Sub Details" label="Sub Details" class="mb-n4"
                  :disabled="!subDetails.length" bg-color="white" v-model="selectedSubDetails" multiple :items="subDetails" chips
        ></v-select>
      </v-col>
      <v-col md="12">
        <v-btn flat color="primary" @click="emitFilters">Apply Filters</v-btn>
        <v-btn flat color="secondary" @click="resetFilters" class="ml-2">Reset Filters</v-btn>
      </v-col>
      <v-col md="12">

</v-col>
    </v-row>
  </v-container>
</template>

<script setup>
/* eslint-disable */
import { ref, watch, defineEmits, onUnmounted, onMounted } from "vue";
import {API_URL} from "../config"

const categories = [
  { text: "Property Status", value: "property_status" },
  { text: "Property Type", value: "prop_class" },
  { text: "Owner Name", value: "owner_name" }
];

const selectedCategory = ref("");
const selectedSubDetails = ref([]);
const ownerName = ref("");
const ownerSearchResults = ref([]);
const ownerLoader = ref(false);
const subDetails = ref([]);
const isCollapsed = ref(false);
const loader = ref(false);

const emit = defineEmits(["update-filters"]);

const handleClickOutside = (event) => {
  const dropdown = document.querySelector(".dropdown");
  const inputDropdown = document.querySelector(".input-dropdown");

  if (dropdown && inputDropdown) {
    if (!inputDropdown.contains(event.target)) {
      ownerSearchResults.value = [];
    }
  }
};

const fetchOwnerByName = async (ownerName) => {
  if (!ownerName) {
    ownerSearchResults.value = [];
    return;
  }
  const url = new URL(`${API_URL}/searchOwner`);
  url.searchParams.append("owner_name", ownerName);
  url.searchParams.append("jwt", localStorage.getItem("authToken"));
  try {
    ownerLoader.value = true;
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      if (data.success && data.matched) {
        ownerSearchResults.value = data.matched.map((item) => ({
          name: item.owner_name,
          phone_number: item.phone_number,
          id: item.owner_name
        }));
      } else {
        ownerSearchResults.value = [];
      }
    } else {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    ownerLoader.value = false;
  } catch (error) {
    console.error("Error fetching owner names:", error);
    ownerLoader.value = false;
  }
};

const selectOwner = (so) => {
  ownerName.value = so.name;
  ownerSearchResults.value = [];
};

watch(selectedCategory, (newVal) => {
  switch (newVal) {
    case "property_status":
      subDetails.value = ["Prospect", "Listing", "Sold", "Dead"];
      break;
    case "prop_class":
      subDetails.value = ["1","15A","15B","15C","15D","15E","15F","2","3A","3B","4A","4B","4C","5A","5B","6A","6B"];
      break;
    case "owner_name":
      subDetails.value = ["R"];
      break;
    default:
      subDetails.value = [];
  }
});


const emitFilters = () => {
  loader.value = true;
  emit("update-filters", {
    category: selectedCategory.value,
    subDetails:
      selectedCategory.value === "owner_name"
        ? [ownerName.value]
        : selectedSubDetails.value
  });
  loader.value = false;
};

const resetFilters = () => {
  selectedCategory.value = "";
  selectedSubDetails.value = [];
  ownerName.value = "";
  ownerSearchResults.value = [];
  emit("update-filters", "default");
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.input-dropdown {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}
</style>
