<template>
    <div class="register-container">
        <h2 class="form-heading">Register</h2>
      <form @submit.prevent="registerForm">
        <div class="form-group">
          <label for="username">Username:</label>
          <input type="text" id="username" v-model.trim="username" required>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model.trim="password" required>
        </div>
        <button type="submit">Register</button>
        <p v-if="error" class="error-message">{{ error }}</p>
      </form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { register } from '@/services/authService'; // Adjust the path as needed
  import router from '@/router/index'; // Import router for navigation
  import { useAuthStore } from '@/store/auth'; 
  
  export default {
    name: 'RegisterForm',
    setup() {
      const username = ref('');
      const password = ref('');
      const error = ref('');
      const authStore = useAuthStore();
  
      const registerForm = async () => {
        if (!username.value || !password.value) {
          error.value = 'Please fill in all fields';
          return;
        }
  
        // Implement registration logic using your service
        try {
          const response = await register(username.value, password.value);
          if (response.success) {
            authStore.setToken(response.tk);
            console.log(authStore.token)
  
            // Redirect to Login or Map page after successful registration
            router.push({ name: 'Map' }); // Navigate to Map page
          } else {
            error.value = response.message;
          }
        } catch (err) {
          error.value = 'An error occurred during registration';
        }
      };
  
      return { username, password, error, registerForm };
    }
  };
  </script>
  
  <style scoped>
  .register-container {
    max-width: 400px;
    margin: auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .form-heading {
    text-align: center;
    color: black; /* Ensure this matches the overall design aesthetic */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button[type="submit"] {
    width: 100%; /* Change to full width for better mobile responsiveness */
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px; /* Added margin top for spacing */
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  </style>