// src/stores/panelStore.js
import { defineStore } from "pinia";

export const useMapStore = defineStore("map", {
  state: () => ({
    map: null,
    editID: null,
    jwt: null,
    editRef: null,
    editDATA: null,
    notes: null,

    propertyChanged: false
  }),
  actions: {
    reFetchEditLayer() {
      try {
        this.map.removeLayer(this.editID);
        this.map.removeSource(this.editRef);
        console.log("Removed edit layer");
      } catch (error) {
        console.error("Error removing edit layer:", error);
      }
      this.map.addSource(this.editRef, {
        type: "geojson",
        data: this.editDATA + this.jwt
      });
      this.map.addLayer({
        id: this.editID,
        type: "fill",
        source: this.editRef,
        paint: {
          "fill-color": [
            "match",
            ["get", "days_since_edit"],
            0,
            "green",
            1,
            "yellow",
            2,
            "red",
            "#ccc"
          ],
          "fill-opacity": 1
        }
      });

      this.map.value.addLayer({
        id: `${this.editID}-outline`,
        type: "line",
        source: this.edit_ref,
        paint: {
          "line-color": "black",
          "line-width": 2
        }
      });
    },
    reFetchProperties() {
      this.propertyChanged = !this.propertyChanged;
    },
    setMap(newMap) {
      this.map = newMap.value;
    },
    setEditID(newEditID) {
      this.editID = newEditID;
    },
    setJWT(newJWT) {
      this.jwt = newJWT;
    },
    setEditDATA(newEditDATA) {
      this.editDATA = newEditDATA;
    },
    setEditRef(newEditRef) {
      this.editRef = newEditRef;
    },
    setNotes(features) {
      console.log("FEATURES");
      console.log(features);
      let notes = [];
      if (features === null) {
        this.notes = null;
        return;
      }
      if (features.length > 0) {
        features.forEach((feature) => {
          let obj = {};
          obj["id"] = feature.properties.edit_id;
          obj["note"] = feature.properties.notes;
          obj["date"] = feature.properties.edit_date;
          obj["pams_pin"]=feature.properties.pams_pin;
          notes.push(obj);
        });
      }

      this.notes = notes;
    },
    getNotes() {
      return this.notes;
    },
    setPropertiesConfig(newPropertiesConfig) {
      this.propertiesConfig = null;
      this.propertiesConfig = newPropertiesConfig;
    },
    getStateValues() {
      console.log(this.map, this.editID, this.jwt, this.editDATA);
    }
  }
});
