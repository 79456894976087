import { useMapStore } from "@/store/mapStore";
import { usePanelStore } from "../store/panel";

export function openPanel(panelIndex) {
  const panelStore = usePanelStore();
  const mapStore = useMapStore();
  panelStore.activePanel = panelIndex;
  panelStore.openPanel(panelIndex);
  if (panelIndex === 3) {
    console.log("REACHING HERE");
    const notes = mapStore.getNotes();
    panelStore.setNotes(notes);
  }
}

export function clearPanel() {
  const panelStore = usePanelStore();
  panelStore.clearPanel();
}

export function setActivePanel(panelIndex) {
  const panelStore = usePanelStore();
  panelStore.setActivePanel(panelIndex);
}
