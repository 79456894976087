<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div class="map-sidebar">
    <p style="text-align: left" class="mb-2">Map Sidebar</p>
    <v-expansion-panels flat rounded v-model="activePanel">
      <!-- Layer Control -->
      <v-expansion-panel bg-color="grey-lighten-5">
        <v-expansion-panel-title
          color="grey-lighten-3"
          style="font-weight: 600"
        >
          <v-icon class="mr-3">mdi-layers-outline</v-icon> Layer Control
        </v-expansion-panel-title>
        <v-expansion-panel-text style="text-align: left">
          <slot name="layercontrol"></slot>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- Filter -->
      <v-expansion-panel bg-color="grey-lighten-5">
        <v-expansion-panel-title
          color="grey-lighten-3"
          style="font-weight: 600"
        >
          <v-icon class="mr-3">mdi-filter-plus-outline</v-icon>Filter
        </v-expansion-panel-title>
        <v-expansion-panel-text style="text-align: left">
          <slot name="filter"></slot>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- Property Info -->
      <v-expansion-panel bg-color="grey-lighten-5">
        <v-expansion-panel-title
          color="grey-lighten-3"
          style="font-weight: 600"
        >
          <v-icon class="mr-3">mdi-text-box-multiple-outline</v-icon> Property
          Info
        </v-expansion-panel-title>
        <v-expansion-panel-text style="text-align: left">
          <v-container class="pa-0 mt-3" fluid v-if="property">
            <v-row class="pa-0">
              <v-col
                md="6"
                class="green"
                v-for="(value, key) in filteredProperty"
                :key="key"
              >
                <p
                  :for="key"
                  style="font-size: 85%; font-weight: 600"
                  class="mb-1"
                >
                  {{ convertToTitleCase(key) }}
                </p>
                <div v-if="key === 'owner_name'" class="input-dropdown">
                  <v-text-field
                    type="text"
                    :id="key"
                    variant="outlined"
                    density="compact"
                    v-model="editedProperty[key]"
                    @input="debounceFetch"
                    class="mb-n5"
                    :placeholder="convertToTitleCase(key)"
                    aria-haspopup="listbox"
                    bg-color="white"
                    :loading="ownerLoader"
                  />
                  <ul
                    v-show="ownerSearchResults.length"
                    class="dropdown mt-n4"
                    role="listbox"
                  >
                    <li
                      v-for="result in ownerSearchResults"
                      :key="result.id"
                      @click="selectOwner(result)"
                      role="option"
                    >
                      {{ result.name }}
                    </li>
                  </ul>
                </div>
                <div
                  v-else-if="key === 'property_status'"
                  class="input-dropdown"
                >
                  <v-select
                    :id="key"
                    variant="outlined"
                    density="compact"
                    class="mb-n5"
                    v-model="editedProperty[key]"
                    bg-color="white"
                    :items="['Sold', 'Dead', 'Prospect', 'Listing']"
                    :placeholder="convertToTitleCase(key)"
                    :value="property[key] || ''"
                  ></v-select>
                </div>
                <div v-else-if="key === 'prop_class'" class="input-dropdown">
                  <v-select
                    :id="key"
                    variant="outlined"
                    density="compact"
                    class="mb-n5"
                    v-model="editedProperty[key]"
                    bg-color="white"
                    :items="[
                      '1',
                      '15A',
                      '15B',
                      '15C',
                      '15D',
                      '15E',
                      '15F',
                      '2',
                      '3A',
                      '3B',
                      '4A',
                      '4B',
                      '4C',
                      '5A',
                      '5B',
                      '6A',
                      '6B'
                    ]"
                    :placeholder="convertToTitleCase(key)"
                    :value="property[key] || ''"
                  ></v-select>
                </div>
                <v-text-field
                  :id="key"
                  variant="outlined"
                  density="compact"
                  class="mb-n5"
                  v-model="editedProperty[key]"
                  bg-color="white"
                  :placeholder="convertToTitleCase(key)"
                  v-else
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div v-else>
            <p>No property selected.</p>
          </div>
          <v-btn
            color="primary"
            class="mt-4"
            flat
            @click.prevent="saveProperty"
          >
            Save Property Info
          </v-btn>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- Note -->
      <v-expansion-panel bg-color="grey-lighten-5">
        <v-expansion-panel-title
          color="grey-lighten-3"
          style="font-weight: 600"
        >
          <v-icon class="mr-3">mdi-note-check-outline</v-icon>Note
        </v-expansion-panel-title>
        <v-expansion-panel-text style="text-align: left">
          <v-textarea
            variant="outlined"
            class="mb-n4 mt-3"
            rows="3"
            placeholder="Enter Note"
            bg-color="white"
            v-model="editedNotes"
          ></v-textarea>
          <v-btn class="mt-3" color="primary" flat @click.prevent="saveNote">
            {{ actionStore.getSaveOrEdit() === "edit" ? "Update" : "Save" }}
            Note
          </v-btn>
          <v-btn
            class="mt-3"
            color="warning"
            flat
            @click.prevent="cancelNoteEditOperation"
            v-if="actionStore.getSaveOrEdit() === 'edit'"
          >
            Cancel
          </v-btn>
          <v-data-table :items="items" :headers="headers" hide-default-footer>
            <template v-slot:item.edit="{ item }">
              <v-btn icon small color="warning" @click="editNote(item.id)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn icon small color="error" @click="deleteNote(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useMapStore } from "../store/mapStore";
import { useActionStore, usePanelStore } from "../store/panel";
import { API_URL } from "../config";
import { clearPanel, openPanel } from "../utils/panelUtils";

export default {
  name: "MapSidebar",
  props: {
    property: Object,
    notes: Object
  },
  setup(props) {
    const panelStore = usePanelStore();
    const editedProperty = ref({ ...props.property });
    const editedNotes = ref("");
    const ownerSearchResults = ref([]);
    const ownerLoader = ref(false);
    const mapStore = useMapStore();
    const actionStore = useActionStore();
    let active_edit_id = null;

    // Notes table
    const items = ref([]);
    const headers = ref([
      { title: "Note", value: "note" },
      { title: "Date", value: "date" },
      { title: "Edit", value: "edit" },
      { title: "Delete", value: "delete" }
    ]);

    const generateEditButton = (item) => {
      return `<v-btn icon small color="warning" @click="editNote(${item.id})"><v-icon>mdi-pencil</v-icon></v-btn>`;
    };

    const generateDeleteButton = (item) => {
      return `<v-btn icon small color="error" @click="deleteNote(${item.id})"><v-icon>mdi-delete</v-icon></v-btn>`;
    };

    const generateDefaultButton = () => {
      return `<v-btn class="mt-3" color="primary" flat @click.prevent="saveNote"> {{ actionStore.getSaveOrEdit() === 'edit' ? 'Update' : 'Save' }} Note </v-btn>`;
    };

    const editNote = async (id) => {
      const noteToEdit = items.value.find((item) => item.id === id);
      if (noteToEdit) {
        editedNotes.value = noteToEdit.note;
        active_edit_id = id;
        actionStore.setSaveOrEdit("edit");
      }
    };

    const deleteNote = async (id) => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(`${API_URL}/deleteNote?jwt=${token}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ edit_id: id })
        });
        if (!response.ok) {
          throw new Error("Error deleting note: " + response.statusText);
        }
        console.log("Note deleted successfully");
        actionStore.setActionCompleted(true, "note");
        handleNotesPanelChange(); // Refresh the notes list
      } catch (error) {
        console.error(error);
      }
    };

    const saveOrEdit = computed(() => actionStore.getSaveOrEdit());

    const activePanel = computed({
      get: () => panelStore.activePanel,
      set: (value) => {
        if (value === 3) {
          handleNotesPanelChange();
        }
        return panelStore.setActivePanel(value);
      }
    });

    watch(
      () => props.property,
      (newVal) => {
        editedProperty.value = { ...newVal };
        if (newVal) {
          ownerSearchResults.value = [];
        }
        openPanel(2);
      }
    );

    watch(
      () => props.notes,
      (newVal) => {
        editedNotes.value = "";
        const currentValue = editedNotes.value;
        if (newVal.length > 0) {
          let string = "";
          if (currentValue !== "") string = "" + currentValue + "\n";
          string += newVal.join("\n");
          console.log(string);

          editedNotes.value = string;
          clearPanel();
          handleNotesPanelChange();
          editedNotes.value = "";
          openPanel(3);
        }
      }
    );

    const token = localStorage.getItem("authToken");

    const saveProperty = async () => {
      const dataToSend = { ...editedProperty.value };
      if (!dataToSend.pams_pin && props.property) {
        dataToSend.pams_pin = props.property.pams_pin;
      }
      try {
        const response = await fetch(`${API_URL}/updateProperty?jwt=${token}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSend)
        });
        if (!response.ok) {
          throw new Error("Error saving property: " + response.statusText);
        }
        editedProperty.value = { ...props.property }; // Reset to original props after update
        actionStore.setActionCompleted(true, "property");
        openPanel(2);
      } catch (error) {
        console.error(error);
      }
    };

    const cancelNoteEditOperation = () => {
      const state = actionStore.getSaveOrEdit();
      actionStore.setActionCompleted(true);
      active_edit_id = null;
      editedNotes.value = "";
    };

    const saveNote = async () => {
      const state = actionStore.getSaveOrEdit();
      // debugger;
      const dataToSend = {
        pams_pin: props.property
          ? props.property.pams_pin
          : props.notes[0].pams_pin,
        notes: editedNotes.value
      };
      // debugger;
      const url =
        state === "edit"
          ? `${API_URL}/updateNote?jwt=${token}`
          : `${API_URL}/addNote?jwt=${token}`;
      if (state === "edit") {
        dataToSend.edit_id = active_edit_id;
      }
      try {
        console.log(dataToSend);
        console.log(url);
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSend)
        });

        if (!response.ok) {
          throw new Error(
            `Error ${state === "edit" ? "updating" : "saving"} note: ${
              response.statusText
            }`
          );
        }

        console.log(
          `Note ${state === "edit" ? "updated" : "saved"} successfully`
        );
        actionStore.setActionCompleted(true, "note");
        active_edit_id = null;
        editedNotes.value = "";
        handleNotesPanelChange(); // Refresh the notes list
        actionStore.setSaveOrEdit("save");
        openPanel(3);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchOwnerByName = async (ownerName) => {
      if (!ownerName) return; // Avoid empty queries
      const url = new URL(`${API_URL}/searchOwner`);
      url.searchParams.append("owner_name", ownerName);
      url.searchParams.append("jwt", token);
      try {
        ownerLoader.value = true;
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (data.success && data.matched) {
            ownerSearchResults.value = data.matched.map((item) => ({
              name: item.owner_name,
              phone_number: item.phone_number,
              email: item.email,
              id: item.owner_name
            }));
          } else {
            ownerSearchResults.value = [];
          }
        } else {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
      } catch (error) {
        console.error("Error fetching owner names:", error);
      } finally {
        ownerLoader.value = false;
      }
    };

    const selectOwner = (so) => {
      editedProperty.value.owner_name = so.name;
      editedProperty.value.phone_number = so.phone_number;
      editedProperty.value.email = so.email;
      ownerSearchResults.value = [];
    };

    const debounceFetch = () => {
      fetchOwnerByName(editedProperty.value.owner_name);
    };

    const convertToTitleCase = (str) => {
      let words = str.split("_");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    };

    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(".dropdown");
      const inputDropdown = document.querySelector(".input-dropdown");
      if (dropdown && inputDropdown && !inputDropdown.contains(event.target)) {
        ownerSearchResults.value = [];
      }
    };

    const handleNotesPanelChange = () => {
      clearPanel();
      const notesArr = mapStore.getNotes();
      items.value = [];
      if (Array.isArray(notesArr) && notesArr.length > 0) {
        items.value = notesArr.map((note) => ({
          note: note.note,
          date: note.date,
          id: note.id,
          edit: generateEditButton(note),
          delete: generateDeleteButton(note)
        }));
      }
    };

    const filteredProperty = computed(() => {
      return Object.keys(props.property || {}).reduce((obj, key) => {
        if (key !== "pams_pin" && key !== "ogc_fid") {
          obj[key] = props.property[key];
        }
        return obj;
      }, {});
    });

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
      handleNotesPanelChange();
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      editedProperty,
      editedNotes,
      ownerSearchResults,
      ownerLoader,
      saveProperty,
      saveNote,
      cancelNoteEditOperation,
      debounceFetch,
      selectOwner,
      convertToTitleCase,
      panelStore,
      activePanel,
      handleNotesPanelChange,
      filteredProperty,
      items,
      headers,
      editNote,
      deleteNote,
      actionStore,
      saveOrEdit
    };
  }
};
</script>

<style scoped>
.input-dropdown {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%; /* Adjust based on the input field width */
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.center-button {
  margin-top: 20px; /* Add some spacing between the button and other content */
}

.elegant-button {
  /* Style your elegant button here */
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.elegant-button:hover {
  background-color: #0056b3;
}
.map-sidebar {
  flex: 0 0 25%; /* Takes up 30% of the flex container's width, does not grow or shrink */
  height: 100vh;
  padding: 15px;
  background-color: white; /* Light grey background for a soft, neutral look */
  box-sizing: border-box;
  overflow-y: auto; /* Allows scrolling if content is too long */
  display: flex;
  flex-direction: column;
  border-left: 2px solid #e0e0e0; /* Subtle right border */
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05); /* Soft shadow to create depth */
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 10px solid #ccc;
  margin: 1em 0;
  padding: 0;
  border-top-color: lightblue;
}
</style>
