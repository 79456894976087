import { createApp } from 'vue';
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue';
import router from './router/index'; // Import the router configuration

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
  })

import { createPinia } from 'pinia'; // Import createPinia
const app = createApp(App);
// Create a Pinia instance
const pinia = createPinia();
// Register Pinia with the Vue application
app.use(pinia);
app.use(vuetify);
// Register the router
app.use(router);
// Mount the application
app.mount('#app');
