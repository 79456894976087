// src/stores/panelStore.js
import { defineStore } from "pinia";

export const usePanelStore = defineStore("panel", {
  state: () => ({
    activePanel: null,
    notes: null
  }),
  actions: {
    openPanel(panelIndex) {
      this.activePanel = panelIndex;
    },
    clearPanel() {
      this.activePanel = null;
    },
    setActivePanel(panelIndex) {
      this.activePanel = panelIndex;
    },
    setNotes(notes) {
      this.notes = notes;
    },
    getNotes() {
      return this.notes;
    }
  }
});

export const useActionStore = defineStore("action", {
  state: () => ({
    actionCompleted: false,
    saveOrEdit: "save",
    latestAttributeEdited: null,
    activeEditLayer: null
  }),
  actions: {
    setActionCompleted(value, editedAttribute) {
      if (this.saveOrEdit === "edit") {
        this.saveOrEdit = "save";
      }
      this.latestAttributeEdited = editedAttribute;
      this.actionCompleted = value;
    },
    setSaveOrEdit(value) {
      this.saveOrEdit = value;
    },
    getSaveOrEdit() {
      return this.saveOrEdit;
    },
    getActiveEditLayer() {
      return this.activeEditLayer;
    },
    setActiveEditLayer(layer) {
      this.activeEditLayer = layer;
    }
  }
});
