<template>
  <div class="login-container">
    <h2 class="form-heading">Login</h2>
    <form @submit.prevent="loginForm">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model.trim="username" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model.trim="password" required>
      </div>
      <button type="submit">Login</button>
      <p v-if="error" class="error-message">{{ error }}</p>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { login } from '@/services/authService';
import { useAuthStore } from '@/store/auth'; // Adjust the path as needed
import router from '@/router/index';

export default {
  name: 'LoginForm',
  setup() {
    const username = ref('');
    const password = ref('');
    const error = ref('');

    const authStore = useAuthStore();

    const loginForm = async () => {
      if (!username.value || !password.value) {
        error.value = 'Please enter both username and password';
        return;
      }

      try {
        const response = await login(username.value, password.value);
        console.log(response);
        if (response.success) {
          authStore.setToken(response.tk);
          console.log(authStore.token)
          router.push({ name: 'Map' });
        } else {
          error.value = response.message;
        }
      } catch (err) {
        error.value = 'An error occurred while logging in';
      }
    };

    return { username, password, error, loginForm };
  }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-heading {
  text-align: center;
  color: black;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button[type="submit"] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>