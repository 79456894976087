<template>
  <div id="layer-control">
    <div class="layer-list" v-show="!isCollapsed">
      <div class="layer-item" v-for="layer in layers" :key="layer.id">
        <div class="layer-header mb-n6">
          <v-checkbox
            :id="layer.id"
            :for="layer.id"
            @change="toggleLayer(layer.id)"
            v-model="layer.visible"
            :label="layer.name"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-tooltip :text="layer.description" location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-information-outline</v-icon>
            </template>
          </v-tooltip>
          <!-- <button class="info-btn" @click="showLayerInfo(layer)">&#9432;</button> -->
        </div>
        <div v-if="layer.id === 'parcels' && subLayers.length" class="sublayer-container ml-4">
          <div class="sublayer-item" v-for="subLayer in subLayers" :key="subLayer.key">
            <div class="color-box" :style="{ backgroundColor: subLayer.color }"></div>
            <span class="sublayer-name">{{ subLayer.name }}</span>
          </div>
        </div>
        <div v-if="layer.id === 'edits'" class="sublayer-container ml-4">
          <div class="sublayer-item">
            <div class="color-box" style="background-color: green"></div>
            <span class="sublayer-name">7 Days</span>
          </div>
          <div class="sublayer-item">
            <div class="color-box" style="background-color: yellow"></div>
            <span class="sublayer-name">14 Days</span>
          </div>
          <div class="sublayer-item">
            <div class="color-box" style="background-color: red"></div>
            <span class="sublayer-name">Grt 14</span>
          </div>
        </div>
      </div>
    </div>
    <div class="layer-info" v-if="selectedLayer">
      <h3>{{ selectedLayer.name }}</h3>
      <p>{{ selectedLayer.description }}</p>
      <button class="close-btn" @click="closeLayerInfo">&times;</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

const layers = ref([
  {
    id: "parcels",
    name: "Parcels",
    visible: true,
    color: "#00ff00",
    description: "Display property parcels on the map."
  },
  {
    id: "mun",
    name: "Municipalities",
    visible: true,
    color: "#ff0000",
    description: "Show municipal boundaries."
  },
  {
    id: "edits",
    name: "Property Edits",
    visible: true,
    color: "#ff0000",
    description: "Highlight edited properties."
  }
]);

onMounted(() => {
  const storedLayers = localStorage.getItem("layers");
  if (storedLayers) {
    layers.value = JSON.parse(storedLayers);
  }
});

watch(
  layers,
  (newLayers) => {
    localStorage.setItem("layers", JSON.stringify(newLayers));
  },
  { deep: true }
);

// eslint-disable-next-line no-undef
const props = defineProps({
  mapInstance: Object,
  subLayers: Array // Passed from parent or using default as above
});

const isCollapsed = ref(false);
const selectedLayer = ref(null);

function toggleLayer(layerId) {
  const layer = layers.value.find((l) => l.id === layerId);
  if (layer) {
    props.mapInstance.setLayoutProperty(
      layerId,
      "visibility",
      layer.visible ? "visible" : "none"
    );
  }
}

function closeLayerInfo() {
  selectedLayer.value = null;
}
</script>

<style scoped>

#layer-control.collapsed {
  width: 40px;
}

.layer-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
}

.layer-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.layer-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.layer-label {
  margin-left: 10px;
  font-weight: normal;
  flex-grow: 1;
}

.sublayer-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.layer-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  z-index: 2;
  text-align: center;
}

.layer-info h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.layer-info p {
  font-size: 16px;
  color: #666;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #333;
}

@media (max-width: 480px) {
  #layer-control {
    top: 50px;
    left: 10px;
    width: calc(100% - 20px);
  }

  #layer-control h2 {
    font-size: 18px;
  }

  .layer-label {
    font-size: 16px;
  }

  .layer-info {
    max-width: 90%;
  }
}
</style>
