<template>
  <div class="home-container">
    <div class="hero-section">
      <h1>Welcome to the Real Estate Canvassing Tool</h1>
      <p>
        You can view propery data, edit attributes and make notes to share with
        your team
      </p>
    </div>
    <div class="form-container">
      <div class="login-container">
        <LoginForm></LoginForm>
      </div>
      <div class="register-container">
        <RegisterForm></RegisterForm>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "./Login.vue";
import RegisterForm from "./Register.vue";

export default {
  name: "HomePage",
  components: {
    LoginForm,
    RegisterForm
  }
};
</script>

<style scoped>
.home-container {
  background: url("~@/assets/sunsethome.jpeg");
  background-size: cover;
  padding: 50px;
  color: #fff;
}

.hero-section {
  text-align: center;
  padding: 100px 20px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  margin-bottom: 30px;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
}

p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.form-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
}

.login-container,
.register-container {
  flex: 1;
  max-width: 360px;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
</style>
