import { createRouter, createWebHistory } from "vue-router";
import HomePageView from "@/components/HomePage.vue";
import MapView from "@/components/Map.vue";
import LoginView from "@/components/Login.vue";
import RegisterView from "@/components/Register.vue";

const backendUrl = "https://backend.gocanvassnow.com/api";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/", // This is the default route
      component: HomePageView,
      meta: { checkForAuth: true }
    },
    {
      path: "/map",
      name: "Map",
      component: MapView,
      meta: { requiresAuth: true }
    },
    {
      path: "/login",
      name: "login",
      component: LoginView
    },
    {
      path: "/register",
      name: "register",
      component: RegisterView
    }
  ]
});

function isAuthenticated() {
  return !!localStorage.getItem("authToken");
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.checkForAuth)) {
    if (isAuthenticated()) {
      const response = await fetch(`${backendUrl}/verify-token`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`
        }
      });
      const data = await response.json();
      if (data.success) {
        next({
          path: "/map"
        });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
