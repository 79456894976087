<template>
  <div class="map-wrap">
    <div class="map" ref="mapContainer"></div>
    <MapSidebar :property="selectedProperty" :notes="selectedPropertyNotes">
      <template v-slot:[`layercontrol`]>
        <LayerControl :mapInstance="map" :subLayers="subLayers" />
      </template>
      <template v-slot:[`filter`]>
        <FilterQuery @update-filters="handleFilterUpdate" />
      </template>
    </MapSidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, onUnmounted, watch, onBeforeUnmount } from "vue"; // Added computed to the import list
import { Map, NavigationControl, GeolocateControl, Popup } from "maplibre-gl";

import {
  parcelid,
  parcellayer,
  parcelHOST,
  parcel_ref,
  munid,
  munlayer,
  munHOST,
  mun_ref,
  editID,
  edit_ref,
  editDATA
} from "../config";

import MapSidebar from "./Sidebar.vue";
import LayerControl from "./LayerControl.vue";
import FilterQuery from "./FilterQuery.vue";
import { useMapStore } from "../store/mapStore";

import { clearPanel, openPanel } from "@/utils/panelUtils";
import { useActionStore } from "@/store/panel";
import maplibregl from "maplibre-gl";
import { GeocodingControl } from "@maptiler/geocoding-control/maplibregl";
import "@maptiler/geocoding-control/style.css";
import "maplibre-gl/dist/maplibre-gl.css";

export default {
  name: "MapVue",
  components: {
    MapSidebar,
    LayerControl,
    FilterQuery
  },
  setup() {
    const mapContainer = ref("");
    const selectedProperty = ref(null);
    const selectedPropertyNotes = ref(null);
    const apiKey = "mx0LZnxQmee0xEQSHpKj";
    const jwt = localStorage.getItem("authToken");
    const map = ref(null);
    const mapStore = useMapStore();
    const actionStore = useActionStore();

    let hoveredStateId = null;
    let munpopup = new Popup({
      closeButton: false,
      closeOnClick: false
    });
    let editpopup = new Popup({
      closeButton: false,
      closeOnClick: true
    });

    const handlePageUnload = (event) => {
      console.log("page is getting loaded");
      localStorage.removeItem("layers");
    };

    const initialSubLayers = [
      { key: "Apartment", name: "Apartment", color: "#FFD0A2" },
      { key: "Commercial", name: "Commercial", color: "#FFA07A" },
      { key: "Farm", name: "Farm", color: "#FFC0CB" },
      { key: "Industrial", name: "Industrial", color: "purple" },
      { key: "Residential", name: "Residential", color: "#99CCFF" },
      { key: "Vacant Land", name: "Vacant Land", color: "#FFFFE0" },
      { key: "Other", name: "Other", color: "#ccc" }
    ];
    const subLayers = ref(initialSubLayers);

    const generateColorMatchExpression = (subDetails) => {
      const getRandomColor = () => {
        const hex = Math.floor(Math.random() * 0xffffff);
        return `#${hex.toString(16).padStart(6, "0")}`;
      };
      const colorMatchExpression = [];
      subDetails.forEach((subDetail) => {
        const color = getRandomColor();
        colorMatchExpression.push(subDetail, color);
        subLayers.value.push({
          name: subDetail,
          color: color,
          key: subDetail
        });
      });
      return colorMatchExpression;
    };

    const removePropertyLayers = async () => {
      try {
        if (map.value.getLayer(parcelid)) map.value.removeLayer(parcelid);
        if (map.value.getSource(parcel_ref)) map.value.removeSource(parcel_ref);
      } catch (error) {
        console.log("Error removing property layer:", error);
      }
    };

    const removeEditLayers = async () => {
      try {
        if (map.value.getLayer(editID)) map.value.removeLayer(editID);
        if (map.value.getLayer(editID+'-outline')) map.value.removeLayer(editID+'-outline');
        if (map.value.getSource(edit_ref)) map.value.removeSource(edit_ref);
      } catch (error) {
        console.log("Error removing edit layer:", error);
      }
    };

    const removeMapLayers = async () => {
      try {
        if (map.value.getLayer(parcelid)) map.value.removeLayer(parcelid);
        if (map.value.getSource(parcel_ref)) map.value.removeSource(parcel_ref);
        if (map.value.getLayer(munid + "line"))
          map.value.removeLayer(munid + "line");
        if (map.value.getLayer(munid)) map.value.removeLayer(munid);
        if (map.value.getSource(mun_ref)) map.value.removeSource(mun_ref);
        if (map.value.getLayer(editID)) map.value.removeLayer(editID);
        if (map.value.getLayer(editID+'-outline')) map.value.removeLayer(editID+'-outline');
        if (map.value.getSource(edit_ref)) map.value.removeSource(edit_ref);
      } catch (error) {
        console.error("Error removing parcel layer:", error);
      }
    };

    watch(
      () => actionStore.actionCompleted,
      async (newVal) => {
        if (newVal) {
          clearPanel();
          const editedAttribute = actionStore.latestAttributeEdited;
          if (editedAttribute === "property") {
            await removePropertyLayers();
            await sleep(1500);
            await addPropertyOverlayLayers();
          } else if (editedAttribute === "note") {
            await removeEditLayers();
            await sleep(1500);
            await addEditOverlayLayers();
          }
          actionStore.setActionCompleted(false);
        }
      }
    );

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const handleFilterUpdate = (filterData) => {
      console.log("Filter data received:", filterData);
      if (filterData === "default") {
        removeMapLayers();
        sleep(1500);
        addOverlayLayers();
        subLayers.value = [];
        initialSubLayers.forEach((subLayer) => {
          subLayers.value.push({
            name: subLayer.name,
            color: subLayer.color,
            key: subLayer.key
          });
        });
      } else {
        const category = filterData.category;
        const filterParams = filterData.subDetails.join(",");
        const newTileURL = `${parcelHOST}${jwt}&category=${category}&filter=${filterParams}`;
        console.log("New tile URL:", newTileURL);
        subLayers.value = [];
        const colorMatchExpression = generateColorMatchExpression(
          filterData.subDetails
        );
        try {
          map.value.removeLayer(parcelid);
          map.value.removeSource(parcel_ref);
        } catch (error) {
          console.error("Error removing parcel layer:", error);
        }
        map.value.addSource(parcel_ref, {
          type: "vector",
          tiles: [newTileURL],
          minzoom: 13,
          maxzoom: 22,
          promoteId: "id"
        });
        map.value.addLayer({
          id: parcelid,
          type: "fill",
          source: parcel_ref,
          "source-layer": parcellayer,
          paint: {
            "fill-color": [
              "match",
              ["get", filterData.category],
              ...colorMatchExpression,
              "#ccc" // Default color for unmatched categories
            ],
            "fill-opacity": 0.5
          }
        });
      }
    };

    const initializeMap = () => {
      if (!apiKey) {
        console.error(
          "API Key is not configured. Check your environment settings."
        );
        return;
      }
      const initialState = { lng: -74.3, lat: 40, zoom: 8 };
      map.value = new Map({
        container: mapContainer.value,
        style: {
          version: 8,
          sources: {
            "carto-dark": {
              type: "raster",
              tiles: [
                "https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
                "https://b.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
                "https://c.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
                "https://d.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png"
              ]
            },
            "carto-light": {
              type: "raster",
              tiles: [
                "https://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png",
                "https://b.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png",
                "https://c.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png",
                "https://d.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png"
              ]
            },
            wikimedia: {
              type: "raster",
              tiles: ["https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"]
            }
          },
          layers: [
            {
              id: "carto-light-layer",
              source: "carto-light",
              type: "raster",
              minzoom: 0,
              maxzoom: 22
            }
          ]
        },
        center: [initialState.lng, initialState.lat],
        zoom: initialState.zoom
      });
      map.value.on("load", () => {
        const gc = new GeocodingControl({ apiKey, maplibregl });
        map.value.addControl(gc);
        map.value.addControl(new NavigationControl(), "top-right");
        map.value.addControl(
          new GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            trackUserLocation: true
          }),
          "top-right"
        );
        addOverlayLayers();
        map.value.on("click", munid, handleMunClick);
        map.value.on("click", parcelid, handlePropertyClick);
        map.value.on("mousemove", parcelid, handelMouseHover);
        map.value.on("mouseleave", parcelid, handleMouseLeave);
        map.value.on("mousemove", munid, munPopup);
        map.value.on("mouseleave", munid, munPopupClose);
        map.value.on("click", editID, handleEditClick);
      });
    };

    const addPropertyOverlayLayers = () => {
      map.value.addSource(parcel_ref, {
        type: "vector",
        tiles: [parcelHOST + jwt],
        minzoom: 13,
        maxzoom: 22,
        promoteId: "ogc_fid"
      });

      map.value.addLayer({
        id: parcelid,
        type: "fill",
        source: parcel_ref,
        "source-layer": parcellayer,
        paint: {
          "fill-color": [
            "match",
            ["get", "classification"],
            "Apartment",
            "#FFD0A2",
            "Commercial",
            "#FFA07A",
            "Farm",
            "#FFC0CB",
            "Industrial",
            "purple",
            "Residential",
            "#99CCFF",
            "Vacant Land",
            "#FFFFE0",
            /* other */
            "#ccc"
          ],
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            1,
            0.5
          ]
        }
      });
    };

    const addEditOverlayLayers = () => {
      map.value.addSource(edit_ref, {
        type: "geojson",
        data: editDATA + jwt
      });
      map.value.addLayer({
        id: editID,
        type: "fill",
        source: edit_ref,
        paint: {
          "fill-color": [
            "match",
            ["get", "days_since_edit"],
            0,
            "green",
            1,
            "yellow",
            2,
            "red",
            "#ccc"
          ],
          "fill-opacity": 1
        }
      });
      map.value.addLayer({
        id: `${editID}-outline`,
        type: "line",
        source: edit_ref,
        paint: {
          "line-color": "black",
          "line-width": 2
        }
      });
    };

    const addOverlayLayers = () => {
      map.value.addSource(mun_ref, {
        type: "vector",
        tiles: [munHOST],
        minzoom: 5,
        maxzoom: 22
      });
      map.value.addLayer({
        id: munid,
        source: mun_ref,
        "source-layer": munlayer,
        type: "fill",
        paint: {
          "fill-color": "rgba(200, 100, 240, 0.4)",
          "fill-outline-color": "rgba(200, 100, 240, 1)",
          "fill-opacity": 0.2
        }
      });
      map.value.addLayer({
        id: munid + "line",
        source: mun_ref,
        "source-layer": munlayer,
        type: "line",
        paint: {
          "line-color": "#000",
          "line-width": 1.3
        }
      });

      map.value.addSource(parcel_ref, {
        type: "vector",
        tiles: [parcelHOST + jwt],
        minzoom: 13,
        maxzoom: 22,
        promoteId: "ogc_fid"
      });

      map.value.addLayer({
        id: parcelid,
        type: "fill",
        source: parcel_ref,
        "source-layer": parcellayer,
        paint: {
          "fill-color": [
            "match",
            ["get", "classification"],
            "Apartment",
            "#FFD0A2",
            "Commercial",
            "#FFA07A",
            "Farm",
            "#FFC0CB",
            "Industrial",
            "purple",
            "Residential",
            "#99CCFF",
            "Vacant Land",
            "#FFFFE0",
            /* other */
            "#ccc"
          ],
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            1,
            0.5
          ]
        }
      });

      map.value.addSource(edit_ref, {
        type: "geojson",
        data: editDATA + jwt
      });
      map.value.addLayer({
        id: editID,
        type: "fill",
        source: edit_ref,
        paint: {
          "fill-color": [
            "match",
            ["get", "days_since_edit"],
            0,
            "green",
            1,
            "yellow",
            2,
            "red",
            "#ccc"
          ],
          "fill-opacity": 1,
        }
      });
      map.value.addLayer({
        id: `${editID}-outline`,
        type: "line",
        source: edit_ref,
        paint: {
          "line-color": "black",
          "line-width": 2
        }
      });

    };

    const handleMunClick = (e) => {
      const features = map.value.queryRenderedFeatures(e.point, {
        layers: [munid]
      });
      if (features.length > 0) {
        let coordinates = e.lngLat;
        if (map.value.getZoom() <= 12.95) {
          map.value.flyTo({
            center: coordinates,
            zoom: 13.1
          });
        }
      }
    };

    const handlePropertyClick = (e) => {
      const features = map.value.queryRenderedFeatures(e.point, {
        layers: [parcelid]
      });
      if (features.length > 0) {
        console.log(features[0].properties);
        selectedProperty.value = features[0].properties;
      }

      mapStore.setNotes(null);

      openPanel(2);
    };

    const handleEditClick = (e) => {
      console.log("TRIGGERED: ", e);
      const features = map.value.queryRenderedFeatures(
        e.point === undefined ? e : e.point,
        {
          layers: [editID]
        }
      );
      mapStore.setJWT(jwt);
      mapStore.setMap(map);
      mapStore.setNotes(features);
      selectedPropertyNotes.value = mapStore.getNotes();
    };

    const handelMouseHover = (e) => {
      const featureId = e.features[0].id;
      if (e.features.length > 0) {
        if (hoveredStateId) {
          map.value.setFeatureState(
            {
              source: parcel_ref,
              sourceLayer: parcellayer,
              id: hoveredStateId
            },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        map.value.setFeatureState(
          { source: parcel_ref, sourceLayer: parcellayer, id: featureId },
          { hover: true }
        );
      }
    };

    const handleMouseLeave = (e) => {
      if (hoveredStateId !== null) {
        map.value.setFeatureState(
          { source: parcel_ref, sourceLayer: parcellayer, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
    };

    const munPopup = (e) => {
      if (map.value.getZoom() <= 12) {
        munpopup
          .setLngLat(e.lngLat)
          .setHTML(e.features[0].properties.mun_label)
          .addTo(map.value);
      }
    };

    const munPopupClose = (e) => {
      munpopup.remove();
    };

    const reFetchEditLayer = () => {
      mapStore.reFetchEditLayer(map, editID, jwt, editDATA);
    };

    onMounted(() => {
      initializeMap();
      window.addEventListener("load", handlePageUnload);
    });
    onUnmounted(() => {
      if (map.value) {
        map.value.remove();
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener("load", handlePageUnload);
    });

    return {
      mapContainer,
      map,
      selectedProperty,
      handleFilterUpdate,
      subLayers,
      reFetchEditLayer,
      selectedPropertyNotes
    };
  }
};
</script>

<style scoped>
@import "~maplibre-gl/dist/maplibre-gl.css";

.map-wrap {
  display: flex;
  height: 100vh;
}

.map {
  flex: 1;
  position: relative;
}
</style>
