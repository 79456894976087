/* eslint-disable */
export const API_URL = "https://backend.gocanvassnow.com/api";
//export const API_URL = 'https://backend.gocanvassnow.com/api';
export const PG_TILSERV_URL = "https://pg-backend.gocanvassnow.com";
export const parcelid = "parcels";
export const parcellayer = "nj.parcels";
export const parcelHOST = `${API_URL}/mvt/${parcellayer}/{z}/{x}/{y}?jwt=`;
export const parcel_ref = "parcel_source";
export const munid = "mun";
export const munlayer = "nj.municipalities";
export const munHOST = `${PG_TILSERV_URL}/${munlayer}/{z}/{x}/{y}.pbf`;
export const mun_ref = "mun_source";
export const editID = "edits";
export const edit_ref = "edit_source";
export const editDATA = `${API_URL}/edits?jwt=`;
