<template>
  <!-- <div id="app"> -->
    <v-app id="app">
    <!-- Render the router-view to display the current route component -->
    <router-view />
  </v-app>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
</style>
